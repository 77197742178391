<script setup>
import { toRefs, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useHeaderStore } from '@/stores/HeaderStore'
import { useCommonStore } from '@/stores/CommonStore'
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'

const emit = defineEmits(['openSubmenu'])
const props = defineProps({
  links: { type: Array, required: true },
  routerLinkClasses: { type: String, default: ''},
  linkClasses: { type: String, default: ''},
  menuBtnClasses: { type: String, default: '' }
})

const {
  links,
  routerLinkClasses,
  linkClasses
} = toRefs(props)

const headerStore = useHeaderStore()
const commonStore = useCommonStore()
const router = useRouter()
const route = useRoute()

const currentPath = computed(() => route.path)

const matchesCurrentPath = (path) => {
  return currentPath.value.includes(path)
}

const closeSubmenu = (link) => {
  if (commonStore.windowInnerWidth >= 1280) {
    link.openedSublinks = false
  } else {
    headerStore.value.updateMenuOpenState(false)
  }
}


</script>

<template>
  <Menu
    as="li"
    v-for="(link, index) in links"
    :key="index"
    class="link-box"
    :class="{ 'has-sublinks': link.subLinks && link.subLinks.length >= 1 }"
  >
    <MenuButton v-if="!link.url" class="menu-btn">
      <router-link
        :to="link.route"
        class="link-item"
        :class="`${link.isSelected || matchesCurrentPath(link.route) ? 'selected' : ''} ${routerLinkClasses} ${menuBtnClasses}`"
        @click="() => emit('openSubmenu', router, links, link)"
      >
        <span v-html="link.title" />
        <Icon
          icon="heroicons-solid:chevron-down"
          class="icon"
          :class="{ 'rotate-180': link.openedSublinks }"
          v-if="link.subLinks && link.subLinks.length >= 1"
        />
      </router-link>
    </MenuButton>
    <a
      v-else
      :href="link.url"
      v-html="link.title"
      class="link-item"
      :class="`${link.isSelected ? 'selected' : ''} ${linkClasses}`"
    />
    <template v-if="link.subLinks && link.subLinks.length >= 1">
      <transition name="scale">
        <div v-show="link.openedSublinks">
          <MenuItems as="ul" class="submenu-links body-regular !translate-y-1" static>
            <MenuItem v-for="(subLink, subIndex) in link.subLinks" :key="subIndex" @click="closeSubmenu(link)" as="li">
              <a v-if="subLink.url" :href="subLink.url" :class='{ "ignore-selected": subLink.ignoreSelected }' class="sub-link">
                <span class="hover:opacity-70 xl:hover:opacity-100">{{ subLink.title }}</span>
              </a>
              <router-link v-else :to="subLink.route" :class='{ "ignore-selected": subLink.ignoreSelected }' class="sub-link">
                <span class="hover:opacity-70 xl:hover:opacity-100">{{ subLink.title }}</span>
              </router-link>
            </MenuItem>
          </MenuItems>
        </div>
      </transition>
    </template>
  </Menu>
</template>

<style scoped>
:deep(.router-link-exact-active.ignore-selected) {
  color: inherit !important;
  font-weight: inherit !important;
  border: none !important;
}
</style>
